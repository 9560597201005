import styled from "styled-components";
import {
	MOBILE_L_BP,
	FONT_WT_HEAVY,
	FONT_WT_LIGHT,
	COLOR_TEXT_PRIMARY_B2U,
} from "@website-builder/utilities-index";

export const Header = styled.h2`
	font-weight: ${FONT_WT_HEAVY};
	font-size: 22px;
	line-height: 28px;
	@media only screen and (max-width: ${MOBILE_L_BP}px) {
		font-size: 18px;
		line-height: 23px;
	}
`;

export const ProgramListWrapper = styled.ul`
	margin-top: 5px;
	padding-left: 20px;
	list-style: square;
	display: flex;
	flex-wrap: wrap;
`;

export const ProgramItemWrapper = styled.div`
	width: calc(50%-10px);
`;

export const ProgramItem = styled.li`
	margin-top: 10px;
	margin-right: 30px;
	width: 40%;
	@media only screen and (max-width: ${MOBILE_L_BP}px) {
		width: 100%;
		margin-right: 10px;
	}
	.navigationLink {
		text-decoration: none;
		text-decoration-line: underline;
		font-size: 18px;
		line-height: 23px;
		color: ${COLOR_TEXT_PRIMARY_B2U};
		cursor: pointer;
		margin-bottom: 15px;
		font-weight: ${FONT_WT_LIGHT};
		& > span,
		& > a {
			color: ${COLOR_TEXT_PRIMARY_B2U};
			text-decoration: none;
		}
		&:hover {
			text-shadow:
				0 0 0.65px #333,
				0 0 0.65px #333;
			cursor: pointer;
		}
	}
`;
