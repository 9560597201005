import React, { useState, useEffect } from "react";
import {
	Header,
	ProgramListWrapper,
	ProgramItem,
	ProgramItemWrapper,
} from "./styles";
import { getStoriesByContentType } from "@website-builder/services";
import { filterStories } from "./transform";
import { CtaLink } from "../../Elements";

const ProgramList = (props) => {
	const { header } = props;
	const [programs, setPrograms] = useState([]);
	let { context, programList = [] } = props;
	const contentType = "Program";

	useEffect(() => {
		if (process.env.GATSBY_TEMPLATE === "PREVIEW") {
			async function fetchData() {
				const { token } = context;
				let result = await getStoriesByContentType(
					contentType,
					token && token.private,
				);
				result = filterStories(result);
				setPrograms(result);
			}
			fetchData();
		}
	}, []);

	const renderProgramList = () => {
		programList =
			process.env.GATSBY_TEMPLATE === "STATIC_SITE" ? programList : programs;
		if (programList.length)
			return programList.map(({ name, full_slug }, index) => {
				return (
					<ProgramItem key={`name-${index}`}>
						<CtaLink
							value={`/${full_slug}`}
							type="external"
							target="_self"
							className="navigationLink"
							key={`${name}-${index}`}
						>
							{name}
						</CtaLink>
					</ProgramItem>
				);
			});
		else return null;
	};

	if (programs.length || programList.length)
		return (
			<section>
				<Header>{header}</Header>
				<ProgramListWrapper>{renderProgramList()}</ProgramListWrapper>
			</section>
		);
	else return null;
};

export default ProgramList;
